import React from 'react'
import {Link} from 'react-scroll';
import {useState, useEffect} from 'react'
import styled from 'styled-components'
import Logo from '../../assets/img/website/logo.png'
import { HiMenuAlt3, HiX} from 'react-icons/hi'
import { ButtonA,Span1,Span2,Span3,Span4 } from '../../style/GlobalStyle'
import TopHeader from './TopHeader';

const Nav = styled.nav`
background: rgba(0, 2, 25, 0.7);
height: 80px;
display: flex;
align-items: center;
justify-content: center;
font-size: 24px;
position: sticky;
top: 0;
z-index: 999;
width: 100%;
`

const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
width: 100%;
`

const NavLogo = styled.div`
color: #fff;
justify-self: flex-start;
cursor: pointer;
text-decoration: none;
font-size: 30px;
display: flex;
align-items: center;
`

const Img = styled.img`
    margin-left: 50px;
    object-fit: contain;
    @media screen and (max-width: 960px){
        margin-left: 15px;
    }
`

const Menu = styled.div`
display: none;
color: #fff;
@media screen and (max-width: 960px){
   display: block;
   position: absolute;
   top: 0;
   right: 0;
   transform: translate(-100%, 60%);
   font-size: 1.8rem;
   cursor: pointer;
}
`
const Navmenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align: center;
@media screen and (max-width: 960px){
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: ${({click}) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: all 0.5s ease ;
    background: rgba(0, 2, 25, 0.7);
}
`

const NavItem = styled.li`
height: 50px;
border-bottom: 2px solid transparent;
&:hover{
    border-bottom: 2px solid #e21f36;
}
@media screen and (max-width: 960px){
    margin-bottom: 25px;
    &:hover{
        border: none;
    }
}
`

const NavLinks = styled.a`
display: flex;
align-items: center;    
text-decoration: none;
height: 100%;
padding: 0.5rem 1rem;
font-size: 20px;
color: #fff;
&:hover{
    color: #fff;
}
@media screen and (max-width: 960px){  
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover{
        color: #4b59f7;
        transition: all 0.3s ease;
    }
}
`

const NavItemBtn = styled.li`
    text-align: center;
@media screen and (max-width: 960px){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
}
`

const NavBtnLink = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 8px 16px;
height: 100%;
width: 100%;
`

const Nav_div = styled.div``

const Navbar = () => {
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() =>{
        showButton()
    }, [])

    window.addEventListener('resize', showButton)
    return (

            // <TopHeader/>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/' onClick={closeMobileMenu}>
                       <Img src={Logo} alt="Moderntech logo" />
                    </NavLogo>

                    <Menu onClick={handleClick}>
                        {click ? <HiX/> : < HiMenuAlt3/>}
                    </Menu>

                    <Navmenu onClick={handleClick} click={click}>
                        <NavItem>
                            <NavLinks href="#home" smooth={true} duration={2000}>Home</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks href="#about" smooth={true} duration={2000}>About</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks href="#services" smooth={true} duration={2000}>Services</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks href="#products" smooth={true} duration={2000}>Products</NavLinks>
                        </NavItem>

                        <NavItem>
                            <NavLinks href="#contact" smooth={true} duration={2000}>Contact</NavLinks>
                        </NavItem>

                        <NavItemBtn>
                            {button ?  (
                                <NavBtnLink>
                                    <ButtonA href="#contact">
                                        Get a Quote
                                    <Span1/>
                                    <Span2/>
                                    <Span3/>
                                    <Span4/>
                                    </ButtonA>
                                </NavBtnLink>
                            ) : (
                                <NavBtnLink>
                                    <ButtonA href="#contact">
                                        Get a Quote
                                    <Span1/>
                                    <Span2/>
                                    <Span3/>
                                    <Span4/>
                                    </ButtonA>
                                </NavBtnLink>
                            )}
                        </NavItemBtn>
                    </Navmenu>

                </NavbarContainer>
            </Nav>

    )
}

export default Navbar
