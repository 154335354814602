import React from 'react'
import { Container } from 'style/GlobalStyle'
import Styled from 'styled-components'
import product_background from '../../assets/img/website/product_background.jpg'
import product from '../../assets/img/website/products.png'
import {FiCodesandbox} from 'react-icons/fi';

const Product_div = Styled.div`
  width: 100%;
  height: 100%;
  margin-top: 150px;
  background: url(${product_background}) rgba(0, 2, 25, 0.95);
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -100;
  @media screen and (max-width: 480px){
    background-attachment: cover;
    }
`

const Row = Styled.div`
    padding: 100px 0;
    width: 100%;
    display: flex;
    z-index: 500;
    @media screen and (max-width: 960px){
        flex-direction: column;
    }
`
const Col1 = Styled.div`
    width: 20%;
    display: flex;
    justify: center;
    align-items: center;
    position: relative;
    height: 100%;
    z-index: 200;
    @media screen and (max-width: 960px){
        width: 100%;
        margin-bottom: 30px;
    }
`
const Col2 = Styled.div`
    width: 80%;
    z-index: 200;
    @media screen and (max-width: 960px){
        width: 100%;
    }
`

const Title_div = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 960px){
        display: none;
    }
`
const Title = Styled.h1`
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    color: #FFFFFF;
    width: 240px;
    border-bottom: 2px solid #E21F36;
`

const Img = Styled.img`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    background: contain;
`

const Inside_Row = Styled.div`
    display: flex;
    width: 100%;
    @media screen and (max-width: 960px){
        flex-direction: column; 
        align-items: center;
        justify-content: center;
    }
`

const Inside_Col = Styled.div`
    width: 50%;
    padding: 18px 35px;
    @media screen and (max-width: 960px){
        width: 100%;
        padding: 18px 0px;
    }
`

const Inside_Title = Styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
`

const Div_Content = Styled.div`
    width: 100%;
    margin-top: 18px;
`
const Product_Content = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 11px;
`

const Icon = Styled.div`
    width: 10%;
    font-size: 25px;
    color: #E21F36;
`

const Product = Styled.div`
    width: 90%;
`

const Product_text = Styled.h3`
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
`

const Title_div_mobile = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;   
`

const Title_mobile = Styled.h3`
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    color: #FFFFFF;
    margin-bottom: 25px;
    width: 240px;
    border-bottom: 2px solid #E21F36;
`

const Hide_Title = Styled.div`
    display: none;
    @media screen and (max-width: 960px){
        display: block;
    }
`

export default function Products() {
    return (
        <Product_div id="products">
        <Container>
            <Row>
                <Hide_Title>
                    <Title_div_mobile>
                        <Title_mobile>OUR PRODUCTS</Title_mobile>
                    </Title_div_mobile>
                </Hide_Title>
                <Col1>
                    <Img src={product} alt="moderntech products" />
                </Col1>

                <Col2>
                    <Title_div>
                        <Title>OUR PRODUCTS</Title>
                    </Title_div>

                    <Inside_Row>
                        <Inside_Col>
                            
                            <Inside_Title>HARDWARE:</Inside_Title>

                            <Div_Content>
                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>POS, POS PRINTER AND PERIPHERALS</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>LAPTOP</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>DESKTOP</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>SERVER STORAGE</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>DESKTOP PRINTER/SCANNER/COPIER</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>BIOMETRICS</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>CARD PRINTER</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>CCTV / IP CAMERA & ALARMS</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>LFD</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>UPS</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>NETWORK DEVICES (SECURITY, FIREWALL, ROUTER, SWITCHES)</Product_text>
                                    </Product>
                                </Product_Content>

                                <Product_Content>
                                    <Icon><FiCodesandbox /></Icon>
                                    <Product>
                                        <Product_text>DATA CENTER / COMMAND CENTER SET UP</Product_text>
                                    </Product>
                                </Product_Content>
                            </Div_Content>
                            

                        </Inside_Col>

                        <Inside_Col>
                            
                            <Inside_Title>SOFTWARE SOLUTION:</Inside_Title>
                            
                            <Div_Content>
                                <Product_Content>
                                <Icon><FiCodesandbox /></Icon>
                                <Product>
                                    <Product_text>FIREWALL</Product_text>
                                </Product>
                                </Product_Content>

                                <Product_Content>
                                <Icon><FiCodesandbox /></Icon>
                                <Product>
                                    <Product_text>ANTI-VIRUS</Product_text>
                                </Product>
                                </Product_Content>

                                <Product_Content>
                                <Icon><FiCodesandbox /></Icon>
                                <Product>
                                    <Product_text>SERVER AND STORAGE SOLUTIONS</Product_text>
                                </Product>
                                </Product_Content>

                                <Product_Content>
                                <Icon><FiCodesandbox /></Icon>
                                <Product>
                                    <Product_text>NETWORK SECURITY</Product_text>
                                </Product>
                                </Product_Content>

                                <Product_Content>
                                <Icon><FiCodesandbox /></Icon>
                                <Product>
                                    <Product_text>BACKUP AND SECURITY</Product_text>
                                </Product>
                                </Product_Content>

                                <Product_Content>
                                <Icon><FiCodesandbox /></Icon>
                                <Product>
                                    <Product_text>DATA SANITIZATION</Product_text>
                                </Product>
                                </Product_Content>
                            </Div_Content>
                            
                        </Inside_Col>
                    </Inside_Row>
                </Col2>

            </Row>
        </Container>
        </Product_div>
    )
}
