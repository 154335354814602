import React from 'react'
import styled from "styled-components"
import { CgPhone, CgFacebook } from 'react-icons/cg';
import {SiIndeed} from 'react-icons/si'
import {FaLinkedinIn} from 'react-icons/fa'

const TopHead = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 999;
    @media screen and (max-width: 480px){
        padding-left: 10px;
        padding-right: 10px;
    }
`

const Contact_div = styled.div`
    display: flex;
    align-items: center;
`
const Phone_icon = styled.div`
    font-size: 25px;
    color: #ffffff;
    @media screen and (max-width: 480px){
        font-size: 23px;
    }
`

const Phone_Number = styled.h3`
    color: #ffffff;
    font-size: 18px;
    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`

const Icons_div= styled.div`
    z-index: 999;
    display: flex;
`

const Icon= styled.a`
    font-size: 20px;
    padding: 5px;
    border-radius: 3px;
    margin-right: 10px;
    color: #ffffff;
    cursor: pointer;
    background: rgba(108, 117, 125, 0.2);
    &:hover{
        color: #ffffff;
    }
    @media screen and (max-width: 480px){
        font-size: 23px;
    }
`

function TopHeader() {
    return (
        <>
        <TopHead>
            <Contact_div>
                <Phone_icon> <CgPhone/> </Phone_icon>
                <Phone_Number>(02) 8808-79-23 to 24</Phone_Number>
            </Contact_div>

            <Icons_div>
                <Icon href="https://www.facebook.com/mcsi.ph" target="_blank"> <CgFacebook/> </Icon>
                <Icon href="https://ph.indeed.com/cmp/Moderntech-Computer-System-Inc" target="_blank"> <SiIndeed/> </Icon>
                <Icon href="https://www.linkedin.com/in/mcsi2008/" target="_blank"> <FaLinkedinIn/> </Icon>
            </Icons_div>

        </TopHead>
        </>
    )
}

export default TopHeader
