import React from 'react'
import styled from "styled-components"
import partner_logo1 from '../../assets/img/website/logo1.png'
import partner_logo2 from '../../assets/img/website/logo2.png'
import partner_logo3 from '../../assets/img/website/logo3.png'
import partner_logo4 from '../../assets/img/website/logo4.png'
import partner_logo5 from '../../assets/img/website/logo5.png'
import partner_logo6 from '../../assets/img/website/logo6.png'
import partner_logo7 from '../../assets/img/website/logo7.png'
import partner_logo8 from '../../assets/img/website/logo8.png'
import partner_logo9 from '../../assets/img/website/logo9.png'
import partner_logo10 from '../../assets/img/website/logo10.png'
import partner_logo11 from '../../assets/img/website/logo11.png'
import partner_logo12 from '../../assets/img/website/logo12.png'
import partner_logo13 from '../../assets/img/website/logo13.png'
import partner_logo14 from '../../assets/img/website/logo14.png'
import partner_logo15 from '../../assets/img/website/logo15.png'
import partner_logo16 from '../../assets/img/website/logo16.png'
import partner_logo17 from '../../assets/img/website/logo17.png'
import partner_logo18 from '../../assets/img/website/logo18.png'
import partner_logo19 from '../../assets/img/website/logo19.png'
import partner_logo20 from '../../assets/img/website/logo20.png'
import partner_logo21 from '../../assets/img/website/logo21.png'
import partner_logo22 from '../../assets/img/website/logo22.png'
import partner_logo23 from '../../assets/img/website/logo23.png'
import partner_logo24 from '../../assets/img/website/logo24.png'
import partner_logo25 from '../../assets/img/website/logo25.png'
import partner_logo26 from '../../assets/img/website/logo26.png'

import { Component } from "react";
import Slider from "react-slick";

const Partners_Div = styled.div`
    width: 100%;
    margin-top: 100px;
`

const Title = styled.h2`
    text-align: center;
    font-weight: 900;
    color: #e21f36;
    font-size: 23px;
    width: 300px;
    border-bottom: 2px solid #E21F36;
`

const Logo = styled.div`
    margin: 50px;
    border: none;
`

const Img = styled.img`
    object-fit: contain;
    height: 80px;
    width: 80%;
    border: none;
    position: relative;
    @media screen and (max-width: 480px){
       height: 180px;
    }
`

const Title_Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export default class PauseOnHover extends Component {
    render() {
      var settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
      };
      return (
        <Partners_Div>
          <Title_Div>
            <Title>Our Business Partners</Title>
          </Title_Div>
          <Slider {...settings}>
          
                <Logo><Img src={partner_logo1} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo2} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo3} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo4} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo5} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo6} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo7} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo8} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo9} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo10} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo11} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo12} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo13} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo14} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo15} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo16} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo17} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo18} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo19} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo20} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo21} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo22} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo23} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo24} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo25} alt="Moderntech partners" /></Logo>
                <Logo><Img src={partner_logo26} alt="Moderntech partners" /></Logo>
            
          </Slider>

        </Partners_Div>
      );
    }
  }