import React from 'react'
import styled from 'styled-components'
import { CgPhone, CgFacebook } from 'react-icons/cg';
import {SiIndeed} from 'react-icons/si'
import {  FaMapMarkerAlt, FaEnvelopeOpenText } from 'react-icons/fa';
import {FiCodesandbox} from 'react-icons/fi';
import {FaLinkedinIn} from 'react-icons/fa'
import Footer_background from '../../assets/img/website/footer_background-min.jpg'
import Logo_footer from '../../assets/img/website/logo.png'

const Footer_div = styled.div`
    padding-top: 150px;
    background: url(${Footer_background}) rgba(0, 2, 25, 0.95);
    background-blend-mode: multiply;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    @media screen and (max-width: 960px){
        padding-top: 50px;
    }
    @media screen and (max-width: 480px){
    background-attachment: cover;
    }
`

const Bottom_Footer = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #1C2354;
    z-index: 100;
`

const CopyRight = styled.div`
    text-align: left;
`

const Privacy_Terms = styled.div`
    text-align: right;
`

const FooterText = styled.div`
    color: #ffffff;
    font-size: 14px;
`
const Top_Footer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 140px;
    z-index: 100;
    @media screen and (max-width: 960px){
        padding-bottom: 0px;
    }
`
const Top_Row = styled.div`
    width: 100%;
    height: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 960px){
       flex-direction: column;
        align-items: flex-start;
    }
`
const Top_Col = styled.div`
    width: 25%;
    font-weight: 900;
    font-size: 25px;
    text-align: center;
    height: 300px;
    margin: 0 10px;
    @media screen and (max-width: 960px){
       width: 100%;
       margin: 0;
       padding-bottom: 30px;
    }
`
const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const Description = styled.h3`
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
`
const Icon_Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`
const Icon = styled.div`
    color: #FFFFFF;
    background: rgba(108, 117, 125, 0.2);
    border-radius: 2px;
    padding: 5px 8px;
    margin: 5px;
    cursor: pointer;
    &:hover{
        transform: translateY(-3px);
        background: #E21F36;
        transition: all ease 0.5s;
    }
`
const Img = styled.img`
    object-fit: contain;
`

const Top_Title_Div = styled.div`
    width: 100%;
    border-bottom: 2px solid #1C2354;
    margin-bottom: 18px;
`
const Top_Title = styled.h2`
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
`
const FooterLinkItems = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`
const Left_Icon = styled.div`
    color: #E21F36;
    margin-right: 15px;
`
const Text = styled.p`
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    text-align: left;
`
const StyledLink = styled.a`
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    text-align: left;
    &:hover{
        color: #FFFFFF;
    }
`
 
const Link = styled.a``

function Footer() {
    return (
        <Footer_div>
            
            <Top_Footer>
                <Top_Row>
                    <Top_Col style={{marginRight: '100px'}}>
                        <Logo>
                            <Img src={Logo_footer} alt="moderntech footer logo" />
                        </Logo>
                        <Description>
                            A highly-competent computer company, we aim to offer you the most advanced solution with no compromises and employing only the best technically-skilled 
                            Field Engineers to give only the most satisfying service to our clients.
                        </Description>

                        <Icon_Div>
                            
                            <Icon>
                                <Link href="https://www.facebook.com/mcsi.ph" target="_blank">
                                    <StyledLink><CgFacebook/></StyledLink>
                                </Link>
                            </Icon>

                            <Icon>
                                <Link href="https://ph.indeed.com/cmp/Moderntech-Computer-System-Inc" target="_blank">
                                    <StyledLink><SiIndeed/></StyledLink>
                                </Link>
                            </Icon>

                            <Icon>
                                <Link href="https://www.linkedin.com/in/mcsi2008/" target="_blank">
                                    <StyledLink><FaLinkedinIn/></StyledLink>
                                </Link>
                            </Icon>

                        </Icon_Div>
                    </Top_Col>

                    <Top_Col>
                        <Top_Title_Div>
                        <Top_Title>Address</Top_Title>
                        </Top_Title_Div>

                        <FooterLinkItems>
                            <Left_Icon><FaMapMarkerAlt/></Left_Icon>
                            <Text>2nd Floor, Bellisima Building, Pacita Ave., Pacita Complex I, San Pedro Laguna 4023</Text>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><CgPhone/></Left_Icon>
                            <Text>(02) 8808-79-23 to 24</Text>
                        </FooterLinkItems>
                    </Top_Col>

                    <Top_Col>
                        <Top_Title_Div>
                        <Top_Title>Quick Links</Top_Title>
                        </Top_Title_Div>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="#home">
                                <StyledLink>Home</StyledLink>
                            </Link>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="#about">
                                <StyledLink>About</StyledLink>
                            </Link>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="#services">
                                <StyledLink>Services</StyledLink>
                            </Link>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="https://ph.indeed.com/cmp/Moderntech-Computer-System-Inc" target="_blank">
                                <StyledLink>Careers</StyledLink>
                            </Link>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="#contact">
                                <StyledLink>Contact Us</StyledLink>
                            </Link>
                        </FooterLinkItems>
                    </Top_Col>

                    {/* <Top_Col>
                        <Top_Title_Div>
                        <Top_Title>Legal</Top_Title>
                        </Top_Title_Div>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="/">
                                <StyledLink>Terms and Conditions</StyledLink>
                            </Link>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="/">
                                <StyledLink>Privacy Policy</StyledLink>
                            </Link>
                        </FooterLinkItems>

                        <FooterLinkItems>
                            <Left_Icon><FiCodesandbox/></Left_Icon>
                            <Link href="/">
                                <StyledLink>Terms of use</StyledLink>
                            </Link>
                        </FooterLinkItems>
                    </Top_Col> */}

                </Top_Row>
            </Top_Footer>

            <Bottom_Footer>
            <CopyRight>
                <FooterText>Copyright © 2021 Moderntech</FooterText>
            </CopyRight>

            {/* <Privacy_Terms>
                <FooterText>Privacy Policy Terms & Conditions</FooterText>
            </Privacy_Terms> */}
            </Bottom_Footer>

        </Footer_div>
    )
}

export default Footer
