import React, {useState} from 'react'
import ModalVideo from 'react-modal-video'
import Company_Video from '../../assets/video/Company_Video.mp4'
// import 'node_modules/react-modal-video/scss/modal-video.scss';

import styled from 'styled-components'
import { PlayBtn_Animation } from '../../style/keyframes';
import { ButtonA, Span1, Span2, Span3, Span4 } from '../../style/GlobalStyle'
import Hero_img from '../../assets/img/website/background.jpg'
import Hero_Content from './Hero_Content'
import Test from './Test'

const Hero = styled.div`
  width: 100%;
  height: 820px;
  margin-top: -130px;
  margin-bottom: -100px;
  background: url(${Hero_img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 960px){
    margin-bottom: 0;
    height: 100vh;
    }
`
const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px){
    flex-direction: column;
  }
`

const Col = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px){
    width: 100%;
  }
`

const Hero_Title = styled.div`
  margin-left: 140px;
  margin-top: -140px;
  @media screen and (max-width: 960px){
    margin-left: 0px;
    margin-top: 100px;
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -100%);
  }
`

const Heading = styled.h1`
  width: 100%;
  color: #ffffff;
  font-size: 65px;
  letter-spacing: 0.08em;
  font-weight: 900;
  z-index: 100;
  @media screen and (max-width: 960px){
    text-align: center;
  }
  @media screen and (max-width: 560px){
    font-size: 35px;
  }
  @media screen and (max-width: 285px){
    font-size: 30px;
  }
`

const SubHeading = styled.h3`
  width: 100%;
  color: #ffffff;
  padding: 8px 0;
  font-weight: 900;
  font-size: 28px;
  z-index: 100;
  @media screen and (max-width: 960px){
    text-align: center;
    font-size: 23px;
  }
  @media screen and (max-width: 285px){
    font-size: 20px;
  }
`
const PlayBtn = styled.div`
  width: 100%;
  height: 100%;
`
const Contact_Btn = styled.div`
  @media screen and (max-width: 960px){
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
`

const Circle = styled.div`
  width: 80px;
  height: 80px;
  background-color: #e21f36;
  border-radius: 50%;
  position: relative;
  display: block;
  margin: 280px auto;
  box-shadow: 0px 0px 9px 8px rgba(226, 31, 54, 0.5 );
  transition: background-color .4s ease-out 100ms;
  &::after{
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  cursor: pointer;
  }
  &::before{
  content: "";
  position: absolute;
  width: 155%;
  height: 155%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, .75);
  top: -27%;
  left: -27%;
  background: rgba(198, 16, 0, 0);
  animation: ${PlayBtn_Animation} 3s infinite;
  cursor: pointer;
  }
  &:hover{
    background-color: #fd7e14;
    box-shadow: 0px 0px 9px 5px rgba(253, 126, 20, 0.3);
  }
  @media screen and (max-width: 960px){
    margin: 30px auto;
  }
`

export default function Home() {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
    <Hero id="home">
        <Row>
          <Col>
            <Hero_Title>
                <Heading>MODERNTECH</Heading>
                <SubHeading>Your Nationwide On-the-Ground Information Technology  Partner.</SubHeading>

                <Contact_Btn>
                  <ButtonA href="#contact">
                    Contact Us
                    <Span1/>
                    <Span2/>
                    <Span3/>
                    <Span4/>
                  </ButtonA>
                </Contact_Btn>

            </Hero_Title>
          </Col>

          <Col>
              {/* <PlayBtn style={{position: 'absolute'}}>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="L61p2uyiMSo" onClose={() => setOpen(false)} />
                <Circle onClick={()=> setOpen(true)}>
                </Circle>
              </PlayBtn> */}
          </Col>
        </Row>
        
    </Hero>
    <Hero_Content />
    </>
  )
}
