import React from 'react'
import styled from 'styled-components'
import Message_icon from '../../assets/img/website/message.png'

const Newsletter_Div = styled.div`
    padding-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 480px){
        padding-left: 8px;
    padding-right: 8px;
    }
`
const Newsletter_Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0F0FA;
    padding: 30px;
    margin-bottom: 50px;
    @media screen and (max-width: 960px){
       flex-direction: column;
       padding: 30px 20px;
    }
`

const MessageIcon = styled.div`
    margin-right: 20px;
`

const NewsletterText = styled.div`
    margin-right: 20px;
`

const Title = styled.h2`
    text-align: left;
    font-weight: 900;
    font-size: 30px;
    color: #000219;
    @media screen and (max-width: 480px){
       font-size: 30px;
       text-align: center;
    }
`

const Description = styled.h3`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
`

const Subscription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 480px){
       margin-top: 20px;
    }
`
const Img = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`

const Input = styled.input`
    background: #FFFFFF;
    border-radius: 5px 0px 0px 5px;
    border: none;
    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
`

const Subscribe_BTN = styled.button`
    background: #E21F36;
    color: #FFFFFF;
    border: none;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 600;
    font-size: 15px;
    border-radius: 0 5px 5px 0;
    @media screen and (max-width: 480px){
        padding-left: 8px;
        padding-right: 8px;
    }
`

export default function Newsletter() {
    return (

        <Newsletter_Div id="newsletter">
            
            <Newsletter_Container>

            <MessageIcon>
                <Img src={Message_icon} alt="Message Moderntech" />
            </MessageIcon>

            <NewsletterText>
                <Title>Sign up for newsletter</Title>
                <Description>Subscribe to the newsletter for all the latest updates</Description>
            </NewsletterText>

            <Subscription>
                <Input></Input>
                <Subscribe_BTN>Subscribe</Subscribe_BTN>
            </Subscription>

            </Newsletter_Container>

        </Newsletter_Div>
    )
}
  