import React, {useState} from 'react'
import Styled from 'styled-components'
import { Container } from 'style/GlobalStyle'
import { Button, Span1, Span2, Span3, Span4 } from 'style/GlobalStyle'
import {  FaMapMarkerAlt, FaEnvelopeOpenText } from 'react-icons/fa';
import { CgPhone,CgFacebook } from 'react-icons/cg';
import {SiIndeed} from 'react-icons/si'
import {FaLinkedinIn} from 'react-icons/fa'
// import { Link} from 'react-router-dom'
import emailjs from 'emailjs-com'

const Result = () =>{
    return(
        <Email_Message>Thank you for contacting us.<br/>
            We have received your inquiry and will respond to you within 24 hours.  
            For urgent inquiries please call us on one of the telephone numbers below.<br/>
            (02) 8808-79-23 to 24 or (02) 8808 79-24
        </Email_Message>
    )
}

const Email_Message = Styled.h2`
    font-weight: 700;
    font-size: 18px;
    margin-top: 18px;
`

const Row = Styled.div`
    padding-top: 150px;
    padding-bottom: 150px;
    width: 100%;
    display: flex;
    @media screen and (max-width: 900px){
       flex-direction: column;
    }
`
const Col = Styled.div`
    width: 50%;
    @media screen and (max-width: 960px){
        width: 100%;
    }
`

const Contact_form = Styled.div`
    padding: 30px;
`

const Title = Styled.h1`
    text-align: left;
    color: #E21F36;
    font-weight: 700;
    font-size: 24px;
    margin-Bottom: 18px;
`
const Form = Styled.form`
    width: 100%;
`
const Form_Group = Styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 18px;
    @media screen and (max-width: 570px){
        flex-direction: column;
    }
`
const Form_Group_wrap = Styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Label = Styled.label`
    font-weight: normal;
    font-size: 18px;
    color: #000219;
`
const Input = Styled.input`
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    height: 35px;
    padding-left: 5px;
    outline: none;
`
const Wrapper = Styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
`
const Textarea = Styled.textarea`
    resize: none;
    width: 100%;
    border: 2px solid #EBEBEB;
    height: 150px;
    outline: none;
`
const Contact_Btn = Styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 18px ;
    z-index: 100;
`

const Contact_details = Styled.div`
    background: #ffffff;
    box-shadow: -8px 8px 20px rgba(0, 0, 0, 0.05);
    padding: 35px;
`

const Sub_title = Styled.h2`
    color: #000219;
    font-size: 20px;
    margin-bottom: 24px;
`

const Details_div = Styled.div``

const LinkItems = Styled.div`
    display: flex;
    margin-bottom: 15px;
`
const Left_Icon = Styled.div`
    color: #E21F36;
    margin-right: 15px;
    font-size: 23px;
    padding-top: 8px;
`
const Text = Styled.p`
    font-weight: 500;
    font-size: 18px;
    color: #000219;
    text-align: left;
`

const Icon_Div = Styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
`
const StyledLink = Styled.a`
    font-weight: 500;
    font-size: 18px;
    color: #4B5280;
    text-align: left;
`


const Icon = Styled.div`
    border: 1px solid #4B5280;
    border-radius: 50%;
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    &:hover ${StyledLink}{
        color: #ffffff;
    }
    &:hover{
        transform: translateY(-3px);
        background: #E21F36;
        transition: all ease 0.5s;
        border: 1px solid #E21F36;
    }
`

const Follow_div = Styled.div``
const Follow_us_title = Styled.div`
    font-weight: 800;
    font-size: 23px;
`

const Message_send = Styled.div``

const Link = Styled.a`
`

export default function Contact() {

    const [result,showResult] = useState (false);
    function sendEmail(e) {
        e.preventDefault();

        emailjs
        .sendForm(
            'service_z55tshn',
            'template_keugeu7',
             e.target,
             'user_iJ1VsLABEXLeDMFYOH6R0'
             )
            .then(
                (result) => {
                 console.log(result.text);
             },
             (error) => {
                console.log(error.text);
             }
            );
            e.target.reset();
            showResult(true);
    }

    //hide result
    setTimeout(() =>{
        showResult(false)
    },5000)

    return (
        <Container style={{background: '#ffffff'}} id="contact">

            <Row>
                <Col style={{marginRight:'20px'}}>
                    <Contact_form>
                    <Title>Contact Us</Title>
                    <Form onSubmit={sendEmail}>
                        <Form_Group>
                            <Form_Group_wrap style={{marginRight:'10px'}}>
                                <Label>Name</Label>
                                <Input type="text" name="name" required/>
                            </Form_Group_wrap>
                            <Form_Group_wrap>
                                <Label>Email</Label>
                                <Input type="email" name="email" required/>
                            </Form_Group_wrap>
                        </Form_Group>
                        <Wrapper>
                            <Label>Subject</Label>
                            <Input type= "text" name="subject" required/>
                        </Wrapper>
                        <Wrapper>
                            <Label>Message</Label>
                            <Textarea name="message" />
                        </Wrapper>

                        <Contact_Btn>
                        <Button type='submit' value='Send'>
                            Send Message
                            <Span1/>
                            <Span2/>
                            <Span3/>
                            <Span4/>
                        </Button>
                        </Contact_Btn>

                        <Message_send>
                            {result ? <Result/> : null}
                        </Message_send>

                    </Form>
                    </Contact_form>
                </Col>

                <Col>
                    <Contact_details>
                        <Title>Our contact details</Title>

                        <Sub_title>
                            Your Nationwide On-the-Ground Information Technology Partner
                        </Sub_title>
                        
                        <Details_div>
                        <LinkItems>
                            <Left_Icon><FaMapMarkerAlt/></Left_Icon>    
                            <Text>2nd Floor, Bellisima Building, Pacita Ave., Pacita Complex I, San Pedro Laguna 4023</Text>
                        </LinkItems>

                        <LinkItems>
                            <Left_Icon><CgPhone/></Left_Icon>
                            <Text>(02) 8808-79-23 to 24</Text>
                        </LinkItems>
                        </Details_div>

                        <Follow_div>
                        <Follow_us_title>Follow us:</Follow_us_title>

                        <Icon_Div>

                            <Link href="https://www.facebook.com/mcsi.ph" target="_blank">
                                <Icon>
                                        <StyledLink><CgFacebook/></StyledLink>                               
                                </Icon>
                            </Link>

                            <Link href="https://ph.indeed.com/cmp/Moderntech-Computer-System-Inc" target="_blank">
                                <Icon> 
                                        <StyledLink><SiIndeed/></StyledLink>
                                </Icon>
                            </Link>

                            <Link href="https://www.linkedin.com/in/mcsi2008/" target="_blank">
                                <Icon>                
                                        <StyledLink><FaLinkedinIn/></StyledLink>
                                </Icon>
                            </Link>


                        </Icon_Div>
                        </Follow_div>
                    </Contact_details>
                </Col>
            </Row>
            
        </Container>
    )
}

