import React from 'react'
import { Container } from '../../style/GlobalStyle'
import styled from 'styled-components'
import About_Img from '../../assets/img/website/map_img.jpg'

const About_Title = styled.div`
    padding-top: 100px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Title_Div = styled.div`
    width: 83%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Heading = styled.h4`
  text-align: center;
  color: #E21F36;
  font-weight: 800;
  font-size: 25px;
  margin-bottom: 20px;
  width: 135px;
  border-bottom: 2px solid #E21F36;
`
const SubHeading = styled.h2`
    font-weight: 800;
    font-size: 20px;
    color: #000219;
    text-align: center;
    @media screen and (max-width: 960px){
      font-size: 20px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px){
        flex-wrap: wrap;
    }
`

const Col = styled.div`
  width: 50%;
  margin: 18px;
  @media screen and (max-width: 960px){
        width: 100%;
    }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Image_Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const About_Us = styled.h2`
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 15px;
  color: #000219;
  @media screen and (max-width: 960px){
    font-size: 18px;
  }
`

const About_Row = styled.div`
  display: flex;
  margin-bottom: 18px;
  @media screen and (max-width: 960px){
       flex-direction: column;
    }
`

const About_Col_Title = styled.h2`
  color: #FFFFFF;
  font-weight: 800;
  font-size: 20px;
  padding-bottom: 8px;
`

const About_Col_Title1 = styled.h2`
  font-weight: 800;
  font-size: 20px;
  padding-bottom: 8px;
  color: #000219;
`

const Description = styled.h3`
  color: #FFFFFF;
  font-weight: normal;
font-size: 15px;
`
const Description1 = styled.h3`
  color: #4B5280;
  font-weight: normal;
  font-size: 15px;
`

const About_Col1 = styled.div`
  margin-right: 15px;
  width: 50%;
  background: #000219;
  padding: 13px;
  border-left: 3px solid #D63384;
  position: relative;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  &::after{
  content: "";
  height: 100%; 
  left: 0; 
  top: 0; 
  width: 0px;  
  position: absolute; 
  /* transition: all 0.3s ease 0s; 
  -webkit-transition: all 0.3s ease 0s;  */
  -webkit-transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  z-index: -1;
  }
  &:hover{
    transform: translateY(-3px);
    transition: all ease 0.5s;
  }
  &:hover::after{
    background: #ffffff;
    width: 100%;
  }
  &:hover{
  ${About_Col_Title}{
    color: #000219;
  }
  ${Description}{
    color: #4B5280;
  }
  }
  @media screen and (max-width: 960px){
       width: 100%;
       margin-top: 8px;
    }
`

const About_Col2 = styled.div`
  margin-right: 15px;
  width: 50%;
  background: #ffffff;
  padding: 13px;
  border-left: 3px solid #E21F36;
  position: relative;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  &::after{
  content: "";
  height: 100%; 
  left: 0; 
  top: 0; 
  width: 0px;  
  position: absolute; 
  /* transition: all 0.3s ease 0s; 
  -webkit-transition: all 0.3s ease 0s;  */
  -webkit-transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  z-index: -1;
  }
  &:hover{
    transform: translateY(-3px);
    transition: all ease 0.5s;
  }
  &:hover::after{
    background: #000219;
    width: 100%;
  }
  &:hover{
  ${About_Col_Title1}{
    color: #ffffff;
  }
  ${Description1}{
    color: #ffffff;
  }
  }
  @media screen and (max-width: 960px){
       width: 100%;
       margin-top: 8px;
    }
`

const About_Col3 = styled.div`
  margin-right: 15px;
  width: 50%;
  background: #ffffff;
  padding: 13px;
  border-left: 3px solid #E21F36;
  position: relative;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  &::after{
  content: "";
  height: 100%; 
  left: 0; 
  top: 0; 
  width: 0px;  
  position: absolute; 
  /* transition: all 0.3s ease 0s; 
  -webkit-transition: all 0.3s ease 0s;  */
  -webkit-transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  z-index: -1;
  }
  &:hover{
    transform: translateY(-3px);
    transition: all ease 0.5s;
  }
  &:hover::after{
    background: #000219;
    width: 100%;
  }
  &:hover{
  ${About_Col_Title1}{
    color: #ffffff;
  }
  ${Description1}{
    color: #ffffff;
  }
  }
  @media screen and (max-width: 960px){
       width: 100%;
       margin-top: 8px;
    }
`

const Span = styled.span`
  font-weight: 700;
  font-size: 16px;
`

const About_Col4 = styled.div`
  margin-right: 15px;
  width: 50%;
  background: #000219;
  padding: 13px;
  border-left: 3px solid #D63384;
  position: relative;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  &::after{
  content: "";
  height: 100%; 
  left: 0; 
  top: 0; 
  width: 0px;  
  position: absolute; 
  /* transition: all 0.3s ease 0s; 
  -webkit-transition: all 0.3s ease 0s;  */
  -webkit-transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  transition: all 0.4s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  z-index: -1;
  }
  &:hover{
        transform: translateY(-3px);
        transition: all ease 0.5s;
  }
  &:hover::after{
    background: #ffffff;
    width: 100%;
  }
  &:hover{
  ${About_Col_Title}{
    color: #000219;
  }
  ${Description}{
    color: #4B5280;
  }
  }
  @media screen and (max-width: 960px){
       width: 100%;
       margin-top: 8px;
    }
`

export default function About() {
  return (
    <>
    <Container id="about">
      <About_Title>  
        <Title_Div>
          <Heading>About us</Heading>
          <SubHeading>
            We will be providing easy access for establishments 
            and individuals for our “One-Stop-Solution Shop“,
            (Services and Repair Centers nationwide) and 
            “Ready-to-go I.T. Support Specialist” right on 
            your doorstep.
          </SubHeading>
        </Title_Div>
      </About_Title>
    <Row>
      <Col>
      <Image_Div>
          <Image src={About_Img} alt="About Background" />
      </Image_Div>
      </Col>

        <Col>
            <About_Us>
              MODERNTECH is an established I.T. service company 
              started year 2003 as a One-Stop-Shop to nationwide service 
              organization. From our base in San Pedro Laguna, our products 
              and services have crossed over into full nationwide service 
              coverage. Its superiority uses on technical expertise in hardware 
              repair services to End-to-End I.T. life cycle services and provider. <br/> <br/>
              As a High competent IT Company, we offer you the most advanced solution with no compromises 
              and employing the best technically-skilled field engineers to give you the high quality 
              service and customer satisfaction as its highest rate are ourselves providing a comprehensive IT solution. 
              
            </About_Us>

            <SubHeading style={{marginBottom: '10px'}}>Certification & Accreditation</SubHeading>

            <About_Row>
              <About_Col1>
                <About_Col_Title></About_Col_Title>
                <Description>
                  {/* Started year 2003 as a One-Stop-Shop to nationwide service organization. 
                  Our products and services have crossed over into full nationwide service coverage. */}
                  <Span>PHILGEPS</Span> (Platinum Membership) <br/>
                  <Span>Lenovo</Span> (Authorized Service Center)
                </Description>
              </About_Col1>

              <About_Col2>
                <About_Col_Title1></About_Col_Title1>
                <Description1>
                  {/* services are available 9/7 and can be 
                  customized to 24/7 in preference to customer requirements for Remote, Onsite and Carry-In SLA.  */}
                  <Span>Samsung</Span> (Authorized Service Center)<br/>
                  <Span>HP</Span> (Authorized Service Provider)
                </Description1>
              </About_Col2>
            </About_Row>

            <About_Row>
              <About_Col3>
                <About_Col_Title1></About_Col_Title1>
                <Description1>
                  {/* Providing Operating System Installation, Application, Reimaging, 
                  Anti-virus Installation, Helpdesk Support, Software Inventory. */}
                  <Span>DELL</Span> (Accredited Solution Provider) <br/>
                  <Span>MICROSOFT</Span> (Cloud Solution Provider)
                </Description1>
              </About_Col3>

              <About_Col4>
                <About_Col_Title></About_Col_Title>
                <Description>
                  {/* Retail Solutions and System Integration under DaaS such as Desktop, Server, Storage , 
                  Laptop, POS machine, Printer Sale, 
                  Device, Part Sales, CCTV, IP Camera & Alarms.  */}
                  <Span>DTI</Span> (2 star computer repair shop) <br/>
                  <Span>NTC</Span> (Mobile phone service center permit)
                </Description>
              </About_Col4>
            </About_Row>
        </Col>
      </Row>
    </Container>
    </>
  )
}
