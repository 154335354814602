import React from 'react'
import styled from 'styled-components'
import Services_Background from '../../assets/img/website/service_background.jpg'
import Service1_icon from '../../assets/img/website/service1.png'
import Service2_icon from '../../assets/img/website/service2.png'
import Service3_icon from '../../assets/img/website/service3.png'
import Service4_icon from '../../assets/img/website/service4.png'
import Service5_icon from '../../assets/img/website/service5.png'
import Service6_icon from '../../assets/img/website/service6.png'
import Service7_icon from '../../assets/img/website/service7.png'
import Service8_icon from '../../assets/img/website/service8.png'

const Service_Div = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 150px;
  background: url(${Services_Background}) rgba(0, 2, 25, 0.95);
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 480px){
    background-attachment: scroll;
    }
`

const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  @media screen and (max-width: 960px){
    padding-right: 50px;
    padding-left: 50px;
    }
    @media screen and (max-width: 480px){
    padding-right: 5px;
    padding-left: 5px;
    }
`

const Services_Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Title_Div = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 960px){
    width: 100%;
    }
`
const Heading = styled.h4`
    font-weight: 800;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
`
const SubHeading = styled.h2`
    text-align: center;
    font-weight: 800;
    font-size: 25px;
    color: #FFFFFF;
    margin-top: 50px;
    width: 230px;
  border-bottom: 2px solid #E21F36;
`

const Service_Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: 960px){
        flex-wrap: wrap;
    }
`

const Img = styled.img`
  object-fit: contain;
`

const Border1 = styled.div`
  top:0;
  left:0;
  width:0;
  height:0;
  border-top:2px solid #e21f36;
  
  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Border2 = styled.div`
  top:0;
  right:0;
  width:10px;
  height:0;
  border-right:2px solid #e21f36;

  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Border3 = styled.div`
  bottom:0;
  right:0;
  width:0;
  height:0;
  border-bottom:2px solid #e21f36;

  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Border4 = styled.div`
  bottom:0;
  left:0;
  width:0;
  height:0;
  border-left:2px solid #e21f36;
  
  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Service_Col = styled.div`
  width: 23%;
  height: 400px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 8px;

  position:relative;
  &:hover{
  ${Border1}{
    width:103%;
  }
  ${Border2}{
    height: 103%;
  }
  ${Border3}{
    width:103%;
  }
  ${Border4}{
    height:103%;
  }
}
  @media screen and (max-width: 960px){
        width: 70%;
        height: 350px;
    }
  @media screen and (max-width: 480px){
    width: 100%;
    height: 330px;
  }
`
const Icon = styled.div`
`
const Title = styled.h2`
  color: #000000;
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 800;

`
const Description = styled.h3`
  text-align: center;
  color: #4B5280;
  font-weight: normal;
  font-size: 16px;
`

export default function Services() {
  return (
      <Service_Div id="services">
        <Container>
        <Services_Title>  
          <Title_Div>
            <Heading>
              Using the state of the art technology, MODERNTECH
              offers the following products and services:
            </Heading>
            <SubHeading>
              OUR SERVICES
            </SubHeading>
          </Title_Div>
        </Services_Title>

        <Service_Row>

          <Service_Col>
            <Icon>
              <Img src={Service1_icon} alt="Moderntech Services" />
            </Icon>
            <Title>MAINTENANCE AGREEMENT</Title>
            <Description>
              Moderntech's Maintenance Agreement contract. Provide corrective repair, 
              proactive and preventive maintenance to all machines covered by Maintenance Agreement.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>

          <Service_Col>
            <Icon>
            <Img src={Service2_icon} alt="Moderntech Services" />
            </Icon>
            <Title>PER CALL REPAIR</Title>
            <Description>
            Service rendered to all PER CALL Customers. Per call rates were given to our clients to cover 
            their different machines like PC desktop, laptop, server, POS and Printer.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>

          <Service_Col>
            <Icon>
            <Img src={Service3_icon} alt="Moderntech Services" />
            </Icon>
            <Title>NETWORK & CABLING SERVICES</Title>
            <Description>
              Diagnostics, Checking and testing of network. 
              Structured cabling services assessment and proposal for data center services.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>


          <Service_Col>
            <Icon>
            <Img src={Service4_icon} alt="Moderntech Services" />
            </Icon>
            <Title>IMAC</Title>
            <Description>
              (INSTALL, MOVE, ADD AND CHANGE) 
              Services types -Installation of Hardware and Software, 
              moving out of machines during site relocation, 
              upgrades, additional installations of Hardware and Software and 
              the reconfigurations of all new set up.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>

          <Service_Col>
            <Icon>
            <Img src={Service5_icon} alt="Moderntech Services" />
            </Icon>
            <Title>OS INSTALLATION</Title>
            <Description>
              Moderntech can perform 
              from single unit installation up to multi unit installation like for example, 
              migration from Windows XP to Windows 7 and 8.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>

          <Service_Col>
            <Icon>
            <Img src={Service6_icon} alt="Moderntech Services" />
            </Icon>
            <Title>OS AND APPLICATION REIMAGING AND INSTALLATION</Title>
            <Description>
              We can perform OS and Application Re-imaging and 
              Installation on our client's locations.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>

          <Service_Col>
            <Icon>
            <Img src={Service7_icon} alt="Moderntech Services" />
            </Icon>
            <Title>NATIONWIDE OS AND APPLICATIONS DEPLOYMENTS</Title>
            <Description>
              A nationwide OS and Application Re-imaging to companies 
              needing nationwide coverage like banks, retail, and companies that 
              have branches located nationwide.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>

          <Service_Col>
            <Icon>
            <Img src={Service8_icon} alt="Moderntech Services" />
            </Icon>
            <Title>HARDWARE / SOFTWARE INVENTORY</Title>
            <Description>
              Inventory to all IT equipments installed to client's location and can produce a 
              machine list report with a detailed information and specifications which this inventory is vital in OS and Application 
              UPGRADES.
            </Description>

            <Border1/>
            <Border2/>
            <Border3/>
            <Border4/>
          </Service_Col>
          
        </Service_Row>

        
        </Container>
        </Service_Div>
  )
}
