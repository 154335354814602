import React from 'react'
import styled from "styled-components";
import Solution_img from '../../assets/img/website/solution_img.jpg'

const Solution_Div = styled.div`
  width: 100%;
  margin-top: 80px;
  padding: 0;
`

const Solution_Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px){
      flex-direction: column;
  }
`

const Solution_Col = styled.div`
  width: 50%;
  padding: 0 20px 0 20px;
  position: relative;
  @media screen and (max-width: 960px){
      width: 100%;
    }
`
const Image_Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px){
      margin-top: 30px;
    }
`
const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Solution_Col1 = styled.div`
  width: 50%;
`

const Heading = styled.h2`
  font-weight: 800;
  font-size: 18px;
  color: #E21F36;
  text-align: center;
  width: 150px;
  border-bottom: 2px solid #E21F36;
`

const SubHeading = styled.h3`
  font-weight: 900;
  font-size: 30px;
  color: #000219;
  text-align: left;
  @media screen and (max-width: 960px){
      font-size: 20px;
  }
`

const Border1 = styled.div`
  top:0;
  left:0;
  width:0;
  height:0;
  border-top:2px solid #e21f36;
  
  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Border2 = styled.div`
  top:0;
  right:0;
  width:10px;
  height:0;
  border-right:2px solid #e21f36;

  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Border3 = styled.div`
  bottom:0;
  right:0;
  width:0;
  height:0;
  border-bottom:2px solid #e21f36;

  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`
const Border4 = styled.div`
  bottom:0;
  left:0;
  width:0;
  height:0;
  border-left:2px solid #e21f36;
  
  position:absolute;
  background:none;
  transition:all .5s ease-in-out;
`


const Support_Div = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  padding: 18px;
  margin-top: 18px;

  position:relative;
  &:hover{
  ${Border1}{
    width:103%;
  }
  ${Border2}{
    height: 103%;
  }
  ${Border3}{
    width:103%;
  }
  ${Border4}{
    height:103%;
  }
}
`

const Support_Title = styled.h2`
  font-weight: 900;
  font-size: 30px;
  color: #000219;
  margin-bottom: 10px;
`

const Support_Description = styled.h3`
  font-weight: normal;
  font-size: 18px;
  color: #000219;
`

const Title_Div = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

export default function Solution() {
    return (
      <>
        <Solution_Div>

          <Solution_Row>

            <Solution_Col>
              <Title_Div>
              <Heading>All-in Solution</Heading>
              <SubHeading>
                Services are available 9/7 and can be 
                customized to 24/7 in preference to customer requirements for 
                Remote, Onsite and Carry-In SLA.
              </SubHeading>
              </Title_Div>
              <Support_Div>
                <Support_Title>Helpdesk Support</Support_Title>
                <Support_Description>
                  We can give your company Helpdesk
                  Support for your Software needs through our available Technical Support 
                  Coordinator and our existing Helpdesk Support System.
                </Support_Description>

                <Border1/>
                <Border2/>
                <Border3/>
                <Border4/>
              </Support_Div>

              <Support_Div>
                <Support_Title>Onsite Tech. Support</Support_Title>
                <Support_Description>
                  We can provide you Technical Person 
                  onsite to be your internal support for particular software OS and 
                  Application need support on an 8hrs, 16hrs and 24hrs by 5 days, 6days 
                  and 7 days coverage as the client require.
                </Support_Description>

                <Border1/>
                <Border2/>
                <Border3/>
                <Border4/>
              </Support_Div>

            </Solution_Col>

            <Solution_Col1>
              <Image_Div>
                <Image src={Solution_img} alt="Moderntech Solution" />
              </Image_Div>
            </Solution_Col1>

          </Solution_Row>

        </Solution_Div>
      </>
    )
  }
  