import React from 'react'
import GlobalStyles from './style/GlobalStyle'
import About from './pages/website/about'
import Hero from './pages/website/hero'
import Services from './pages/website/Services'
import Solution from './pages/website/Solution'
import Partners from './pages/website/Partners'
import Newsletter from './pages/website/Newsletter'
import Footer from './pages/website/Footer'
import TopHeader from './pages/website/TopHeader'
import Navbar from './pages/website/Navbar'
import Contact from './pages/website/Contact'
import Products from 'pages/website/Products'

import MessengerCustomerChat from 'react-messenger-customer-chat';
import { right } from '@popperjs/core'


export default function HomePage() {
  return (
    <>
      <GlobalStyles/>
      <TopHeader/>
      <Navbar/>
      <Hero/>
      <About/>
      <Services/>
      <Solution />
      <Products/>
      <Contact />
      <Partners/>
      {/* <Newsletter/> */}
      <Footer />
    {/* <MessengerCustomerChat pageId="344682536259710" appId="1053003828794244" /> */}
    </>
  )
}
