import React from 'react'
import styled from "styled-components";
import icon1 from '../../assets/img/website/hero_icon1.png'
import icon2 from '../../assets/img/website/hero_icon2.png'
import icon3 from '../../assets/img/website/hero_icon3.png'

const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 960px){
        margin-top: 40px;
        flex-wrap: wrap;
    }
`

const Col = styled.div`
    width: 450px;
    height: 250px;
    background: #000219;
    margin: 18px;
    @media screen and (max-width: 1024px){
        height: 300px;
    }
    @media screen and (max-width: 960px){
        width: 100%;
        height: 220px;
    }
`

const Img = styled.img`
    object-fit: contain;
`

const Hero_Title = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
`

const Icon = styled.div`
    padding: 10px;
    width: 25%;
`

const Title = styled.h3`
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
`

const Title_Div = styled.div`
    width: 75%;
`

const Description_Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 60%;
    padding-bottom: 18px;
`

const Description = styled.h4`
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
`

export default function Home() {
    return (
      <>
      
        <Row>
            <Col>
                <Hero_Title>
                    <Icon>
                        <Img src={icon1} alt="" />
                    </Icon>
                    
                    <Title_Div>
                        <Title>
                            One-location repair shop
                        </Title>
                    </Title_Div>

                </Hero_Title>

                <Description_Div>
                    <Description>
                        Nationwide service organization. End-to-end IT lifecycle services provider
                        Procurement, deployment, Support, maintenance Decommissioning, etc.
                    </Description>
                </Description_Div>
            </Col>

            <Col>
                <Hero_Title>
                    <Icon>
                    <Img src={icon2} alt="" />
                    </Icon>
                    
                    <Title_Div>
                        <Title>
                        CORPORATE & RETAIL SOLUTIONS INTEGRATION 
                        </Title>
                    </Title_Div>

                </Hero_Title>

                <Description_Div>
                    <Description>
                        We provide your company’s IT requirements focused in supplying high quality products 
                        at the most affordable price designed to meet all your hardware & software needs. 
                    </Description>
                </Description_Div>
            </Col>

            <Col>
                <Hero_Title>
                    <Icon>
                    <Img src={icon3} alt="" />
                    </Icon>
                    
                    <Title_Div>
                        <Title>
                            Carry-in daytime service
                        </Title>
                    </Title_Div>

                </Hero_Title>

                <Description_Div>
                    <Description>
                        Remote, Onsite, and carry-in
                        Services available 24x7
                        SLAs customized to client needs.
                    </Description>
                </Description_Div>
            </Col>

        </Row>
      </>
     
    )
}